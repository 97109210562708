import React, { useRef, useState } from "react";
import {
    SimpleGrid,
    Flex,
    Input,
    Button,
    Textarea,
    FormLabel,
    Select,
    Checkbox,
    Text,
    Link,
    Box,
    Heading,
    Image,
} from "@chakra-ui/react";
import { IoLogoTwitter } from "react-icons/io5";

const FORM_STATES = {
    INITIAL: "INITIAL",
    SUBMITTED_EMAIL: "SUBMITTED_EMAIL",
    SUBMITTED_SURVEY: "SUBMITTED_SURVEY",
};

const Wrapper = ({ children, ...restProps }) => (
    <Flex
        justifyContent="center"
        bg="gray.50"
        flexDir="column"
        alignItems="center"
        {...restProps}
    >
        <Box maxW={{ md: "100%", lg: "60%" }}>{children}</Box>
    </Flex>
);
const ComingSoonEmailCaptureForm = () => {
    const [formState, setFormState] = useState(FORM_STATES.INITIAL);
    const [email, setEmail] = useState("");
    // const [primaryUseCase, setPrimaryUseCase] = useState("");
    // const [secondaryUseCase, setSecondaryUseCase] = useState("");
    // const [operatingSystem, setOperatingSystem] = useState("");
    // const [smartPhoneOperatingSystem, setSmartPhoneOperatingSystem] =
    //     useState("");
    // const [additionalInfo, setAdditionalInfo] = useState("");
    // const [isWillingToBetaTest, setIsWillingToBetaTest] = useState(false);
    // const [teslaType, setTeslaType] = useState("");

    const emailFormRef = useRef();
    const surveyFormRef = useRef();

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setFormState(FORM_STATES.SUBMITTED_EMAIL);

        fetch("https://submit-form.com/gsEFOn5w", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                email,
            }),
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const handleSurveySubmit = () => {
        setFormState(FORM_STATES.SUBMITTED_SURVEY);

        const teslaType = document.getElementById("tesla-type").value;
        const operatingSystem =
            document.getElementById("operating-system").value;
        const smartPhoneOperatingSystem =
            document.getElementById("smartphone").value;
        const primaryUseCase =
            document.getElementById("primary-use-case").value;
        const additionalInfo = document.getElementById("additional-info").value;
        const isWillingToBetaTest = document.getElementById("beta").value;

        fetch("https://submit-form.com/e2gmaE5Y", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                email,
                primaryUseCase,
                // secondaryUseCase,
                operatingSystem,
                smartPhoneOperatingSystem,
                additionalInfo,
                isWillingToBetaTest,
                teslaType,
            }),
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.error(error);
            });
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <Wrapper bg="white" pt="80px" pb="80px">
            <form
                ref={emailFormRef}
                onSubmit={handleEmailSubmit}
                style={{
                    display:
                        formState === FORM_STATES.INITIAL ? "block" : "none",
                }}
                id="signup"
            >
                <Heading as="h2" fontSize="22pt" mb="28px" textAlign="center">
                    Launching in 2023
                </Heading>
                <Heading as="h3" fontSize="14pt" mb="34px" textAlign="center">
                    Sign up to be the first to know when we launch.
                </Heading>

                <Flex
                    direction={{ sm: "column", md: "row" }}
                    align="stretch"
                    boxShadow="0px 0px 0px 8px rgba(0,0,0,0.05)"
                    mb="20px"
                    borderRadius="10px"
                >
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        minW="400px"
                        required=""
                        onChange={handleEmailChange}
                        mr="0px"
                        h="60px"
                        fontSize="20px"
                        pl="40px"
                        pr="40px"
                        borderRadius="10px"
                        borderTopRightRadius={["10px", "10px", "0px", null]}
                        borderBottomRightRadius={["00px", "0px", "0px", null]}
                        borderBottomLeftRadius={["0px", "0px", "10px", null]}
                    />
                    <Button
                        borderTopLeftRadius={["0px", "0px", "0px", null]}
                        borderBottomLeftRadius={["10px", "10px", "0px", null]}
                        borderTopRightRadius={["0px", "0px", "10px", null]}
                        flex="0 0 auto"
                        color="white"
                        h="60px"
                        fontSize="20px"
                        pl="40px"
                        pr="40px"
                        borderRadius="10px"
                        bg="rgba(0,0,0,0.9)"
                        _hover={{
                            color: "white",
                            bg: "black",
                        }}
                        _active={{
                            color: "white",
                            bg: "black",
                        }}
                        type="submit"
                        onClick={handleEmailSubmit}
                    >
                        Get early access
                    </Button>
                </Flex>
                <Text>No spam, ever. Unsubscribe any time.</Text>
            </form>
            <form
                ref={surveyFormRef}
                style={{
                    display:
                        formState === FORM_STATES.SUBMITTED_EMAIL
                            ? "block"
                            : "none",
                    textAlign: "center",
                }}
            >
                <Flex direction="column" alignItems="center">
                    <Image src="hedgehog2.png" height="250px" width="250px" />
                    <Text fontSize="xl" mb="20px">
                        <strong>Thanks! We'll be in touch soon.</strong>
                    </Text>
                    <Text fontSize="md" mb="20px" textAlign="left">
                        <strong>Help us develop DashDisplay (optional):</strong>
                    </Text>
                </Flex>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required=""
                    value={email}
                    display="none"
                />
                <FormLabel>Which Tesla do you drive?</FormLabel>
                <Select required="" id="tesla-type" mb="20px">
                    <option value="model-s-x-vertical">
                        2012-2021 Model S / X (Vertical screen)
                    </option>
                    <option value="model-s-x-horizontal">
                        2021-2023 Model S / X (Horizontal screen & yoke)
                    </option>
                    <option value="model-3-y-2018-2021">
                        2018-2021 Model 3 / Y
                    </option>
                    <option value="model-3-y-2022-2023">
                        2022-2023 Model 3 / Y
                    </option>
                </Select>
                <FormLabel>What type of laptop do you use?</FormLabel>
                <Select
                    id="operating-system"
                    name="operating-system"
                    required=""
                    mb="20px"
                >
                    <option value="mac">Mac</option>
                    <option value="windows">Windows</option>
                    <option value="linux">Linux</option>
                </Select>
                <FormLabel>And your smartphone?</FormLabel>
                <Select id="smartphone" name="smartphone" required="" mb="20px">
                    <option value="iphone">iPhone</option>
                    <option value="android">Android</option>
                </Select>
                <FormLabel>What do you hope to use DashDisplay for?</FormLabel>
                <Select
                    id="primary-use-case"
                    name="primary-use-case"
                    required=""
                    mb="20px"
                >
                    <option value="work-email-messaging">
                        Work - emails and messaging
                    </option>
                    <option value="work-coding">Work - coding</option>
                    <option value="work-office-apps">
                        Work - office apps (writing, spreadsheets,
                        presentations)
                    </option>
                    <option value="work-video-conferencing">
                        Work - video conferencing
                    </option>
                    <option value="watching-videos">Watching videos</option>
                    <option value="gaming">Gaming</option>
                    <option value="other">Other (please explain below)</option>
                </Select>
                {/* <FormLabel>Secondary use case for DashDisplay?</FormLabel>
                <Select
                    id="secondary-use-case"
                    name="secondary-use-case"
                    required=""
                    value={secondaryUseCase}
                    onChange={(e) => {
                        setSecondaryUseCase(e.target.value);
                    }}
                >
                    <option value="gaming">Gaming</option>
                    <option value="watching-videos">Watching videos</option>
                    <option value="neutral">Neutral</option>
                    <option value="disagree">Disagree</option>
                    <option value="strongly-disagree">Strongly Disagree</option>
                </Select> */}
                <FormLabel>Anything else?</FormLabel>
                <Textarea
                    id="additional-info"
                    placeholder="Anything other suggestions or questions for the DashDisplay team"
                    required=""
                    mb="20px"
                />
                <Flex alignItems="center" mb="20px">
                    <Checkbox
                        id="beta"
                        name="beta"
                        required=""
                        defaultChecked={false}
                        size="lg"
                        mr="10px"
                    />
                    <FormLabel htmlFor="beta" mb="0">
                        Want to beta test? We'll reach out.
                    </FormLabel>
                </Flex>
                <Box>
                    <Button
                        onClick={handleSurveySubmit}
                        colorScheme="blue"
                        variant="solid"
                        width="100%"
                    >
                        Submit
                    </Button>
                </Box>
            </form>

            {formState === FORM_STATES.SUBMITTED_SURVEY && (
                <Flex flexDirection="column" w="100%" textAlign="center">
                    <Text fontSize="xl" mb="20px">
                        <strong>Thanks!</strong>
                    </Text>
                    <Button
                        as={Link}
                        colorScheme="blue"
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            "Check out https://DashDisplay.io – Use your Tesla as a second monitor while parked."
                        )}`}
                        variant="solid"
                    >
                        <IoLogoTwitter />
                        Spread the word
                    </Button>
                </Flex>
            )}
        </Wrapper>
    );
};

export default ComingSoonEmailCaptureForm;
