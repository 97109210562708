import {
    Badge,
    Box,
    Button,
    chakra,
    Container,
    Flex,
    Heading,
    Image,
    Img,
    Link,
    Stack,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import { motion, isValidMotionProp } from "framer-motion";
import { ImArrowRight2 } from "react-icons/im";

const TextLoop = ({ children, ...restProps }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const intervalMs = 1500;

    const words = [
        "office",

        //"gaming rig", "theater"
    ];

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (selectedIndex >= words.length - 1) {
                setSelectedIndex(0);
            } else {
                setSelectedIndex(selectedIndex + 1);
            }
        }, intervalMs);

        return () => clearInterval(interval);
    }, [selectedIndex]);

    return (
        <Box
            as={motion.div}
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            transition="0.1s linear"
            key={selectedIndex}
            display="inline-block"
            {...restProps}
            color="blue.500"
        >
            {words[selectedIndex]}
        </Box>
    );
};

const Hero = () => (
    <>
        <Flex as="section" bg="white" pt="50px">
            <Container
                as="footer"
                role="contentinfo"
                maxW="100ch"
                textAlign="center"
                zIndex="1000"
            >
                <Box position="relative">
                    <Heading
                        as="h1"
                        fontSize={{ base: "24pt", md: "40pt" }}
                        fontWeight="900"
                        //   color="white"
                        //   textShadow="5px 5px 5px rgba(0,0,0,0.1)"
                        mt="20px"
                        mb="20pt"
                    >
                        <Text color="blue.500" mb="0">
                            Supercharge
                        </Text>
                        your mobile office
                        {/* <TextLoop interval={100} pl="7px" /> */}
                    </Heading>

                    <Text fontSize="20pt" fontWeight="600" mb="60px">
                        Use your Tesla as a second monitor while parked.
                    </Text>

                    <Box>
                        <Button
                            mb="40px"
                            color="white"
                            h="60px"
                            fontSize="20px"
                            pl="40px"
                            pr="40px"
                            borderRadius="30px"
                            bg="rgba(0,0,0,0.9)"
                            _hover={{
                                color: "white",
                                bg: "black",
                                boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.1)"
                            }}
                            _active={{
                                color: "white",
                                bg: "black",
                                boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.1)"
                            }}
                            as={Link}
                            href="#signup"
                            boxShadow="0px 0px 0px 8px rgba(0,0,0,0.1)"
                        >
                            Get early access
                            {/* Buy now - $29 one-time payment&nbsp;<ImArrowRight2 /> */}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Flex>
            <Box
                backgroundImage="tesla-screen.jpg"
                backgroundSize={{ base: "cover", md: "cover" }}
                backgroundRepeat="no-repeat"
                backgroundPosition={{ base: "center", md: "center" }}
                pos="relative"
                height={0}
                pt="55.28%"
                mt={{ base: "0", sm: "0", md: "0", lg: "-60px", xl: "-100px" }}
            />
    </>
);

export default Hero;
