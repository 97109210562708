// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
// 2. Call `extendTheme` and pass your custom values
export default extendTheme({
  styles: {
    global: (props) => ({
      // Remove Chakra's default border focus outline and use :focus-visible instead
      ".js-focus-visible :focus:not([data-focus-visible-added])": {
        outline: "none",
        boxShadow: "none",
      },
      "html, body": {
        scrollBehavior: "smooth",
        color: props.colorMode === "dark" ? "white" : "gray.600",
        backgroundColor: "gray.50",
      },

      img: {
        userSelect: "none !important",
      },
    }),
  },
  colors: {},
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});
