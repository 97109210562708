import React from "react";
import { Text, Box, Container, Flex, HStack, Link, Select } from '@chakra-ui/react';
import { BiChevronRight } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";

/*
- Overview
   - why?
   - features
- Compatibility
   - vehicle
   - laptop

- Installation
- Setup
  - Connect hotspot to laptop via USB
  - Turning on internet sharing
  - Connect tesla to dashd.io

- Usage
  - Screen reconnection
  - latency

- Coming soon
  - audio
  - windows support
*/

const OverviewContent = () => {
    return (
        <Box margin="0 auto" maxW="1000px" h="100%" p={4}>
            <Text mt="10px">
            DashDisplay is in beta testing. Documentation and public release are coming soon!<br/><br/>
            Interested? <Text color="blue.600" display="inline-block"><a href="/#signup">Sign up for our beta.</a></Text>
            </Text>
        </Box>
    )
}

const menuItems = [
    {
        name: 'Overview',
        path: '/docs/overview',
        component: OverviewContent
    },
    {
        name: 'Installation',
        path: '/docs/overview',
        component: OverviewContent
    },
    {
        name: 'Device Setup',
        path: '/docs/overview',
        component: OverviewContent
    },
    {
        name: 'Vehicle Setup',
        path: '/docs/overview',
        component: OverviewContent
    },
    {
        name: 'Support',
        path: '/docs/overview',
        component: OverviewContent
    }
]

const MenuItemLink = ({ menuItem }) => {
    const {
        name,
        path
    } = menuItem;
    const navigate = useNavigate();
    return (
        <Box mb="9px">
            <Link color="gray.700" textDecoration="" fontWeight="500" href={path} onClick={(e) => {
                navigate(path);
                e.preventDefault();
                e.stopPropagation();
            }}>
            {name}
            </Link>
        </Box>
    )
}

export default function Documentation() {
    return (
        <Box
            flex="1"
            borderTop="1px solid rgba(0,0,0,0.1)"
            display="flex"
            flexDir="column"
            justifyContent="stretch"
            alignItems="stretch"
        >
            <Box
                flex="0 0 auto"
                borderBottom="1px solid rgba(0,0,0,0.1)"
                bg="white"
                h="60px"
            >
                <Box
                    display={"flex"}
                    margin="0 auto"
                    maxW="1000px"
                    h="100%"
                    p={4}
                    flexDir="row"
                    justifyContent="stretch"
                    alignItems="stretch"
                    userSelect="none"
                >
                    <HStack>
                        <Text color="gray.500">DashDisplay</Text>
                        <BiChevronRight />
                        <Text color="gray.500">User Guide</Text>
                        <BiChevronRight />
                        <Text color="gray.800">Overview</Text>
                    </HStack>
                </Box>
            </Box>
            <Box
                flex="0 0 auto"
                borderBottom="1px solid rgba(0,0,0,0.1)"
                h="60px"
                bg="linear-gradient(0deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.5) 100%);"
                display={["flex", "flex", "flex", "none"]}
                flexDir="row"
                justifyContent="flex-end"
                alignItems="center"
                pl="10px"
                pr="10px"
            >
                <Select>
                    <option>Test</option>
                </Select>
            </Box>
            <Box
                flex="1"
                display={"flex"}
                flexDir="row"
                justifyContent="center"
                alignItems="stretch"
            >
                <Box
                    flex="0 0 auto"
                    minW="250px"
                    maxW="250px"
                    p="30px"
                    pt="30px"
                    pb="30px"
                    borderRight="1px solid rgba(0,0,0,0.05)"
                    zIndex="0"
                    position="relative"
                    display={["none", "none", "none", "block"]}
                >
                    <Box
                        position="absolute"
                        top="0"
                        bottom="0"
                        right="0"
                        width="600px"
                        bg="linear-gradient(90deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.5) 100%);"
                        zIndex="-1"
                    >

                    </Box>
                    <Box
                        width="100%"
                        zIndex="1"
                    >
                        {menuItems.map((item) => <MenuItemLink menuItem={item} />)}
                    </Box>
                </Box>
                <Box
                    flex="1"
                    maxWidth="1200px"
                    p="20px"
                    pt="30px"
                    pb="30px"
                >
                    <Text mt="10px">
                    DashDisplay is in beta testing. Documentation and public release are coming soon!<br/><br/>
                    Interested? <Text color="blue.600" display="inline-block"><a href="/#signup">Sign up for our beta.</a></Text>
                    </Text>
                </Box>
            </Box>
        </Box>
    );
}
