import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import chakraTheme from "../constants/chakraTheme";
import { useNavigate } from 'react-router-dom';

const Logo = () => {
  const navigate = useNavigate();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={() => navigate("/")}
    >
      <Flex
        width="32px"
        height="25px"
        borderRadius="5px"
        borderColor="blue.500"
        borderWidth="3px"
        justifyContent="center"
        alignItems="center"
      >
        <BsFillLightningChargeFill
          size="15"
          color={chakraTheme.colors.blue[500]}
        />
      </Flex>

      <Text fontWeight="700" fontSize="16pt" ml="10px">
        DashDisplay
      </Text>
    </Flex>
  );
};

export default Logo;
