import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Flex,
    HStack,
    IconButton,
    Link,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { FiMenu, FiMonitor } from "react-icons/fi";
import { BsFillLightningChargeFill } from "react-icons/bs";
import chakraTheme from "../constants/chakraTheme";
import Logo from "./Logo";
import { FaExternalLinkAlt } from "react-icons/fa";

const Navbar = () => {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    });
    return (
        <Box
            as="section"
            //   pb={{
            //     base: "12",
            //     md: "24",
            //   }}
            pos="sticky"
        >
            <Box
                as="nav"
                bg="white" //boxShadow={useColorModeValue("sm", "sm-dark")}
            >
                <Container
                    py={{
                        base: "4",
                        lg: "5",
                    }}
                    maxW="100ch"
                >
                    <HStack spacing="10" justify="space-between">
                        {isDesktop && <Logo />}
                        {!isDesktop && (
                            <HStack justify="center" align="center">
                                <Logo />
                            </HStack>
                        )}

                        {isDesktop && (
                            <Flex justify="space-between" flex="1">
                                <ButtonGroup variant="link" spacing="8">
                                    <Button as="a" href="/#features">
                                        Features
                                    </Button>
                                    {/* <Button as="a" href="/#pricing">Pricing</Button> */}
                                </ButtonGroup>
                                <HStack spacing="3">
                                    <Button
                                        variant="ghost"
                                        as={Link}
                                        onClick={(e) => {
                                            if (window.$crisp) {
                                                window.$crisp.push(['do', 'chat:open']);
                                            }
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        Support&nbsp;&nbsp;
                                        <FaExternalLinkAlt />
                                    </Button>
                                </HStack>
                            </Flex>
                        )}
                    </HStack>
                </Container>
            </Box>
        </Box>
    );
};

export default Navbar;
