import {
    Box,
    Container,
    Heading,
    Icon,
    SimpleGrid,
    Square,
    Stack,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";

import {
    BsApple,
    BsFillMoonFill,
    BsStars,
    BsWifi,
    BsWindows,
} from "react-icons/bs";
import { FaAccessibleIcon, FaExpandAlt, FaPaintBrush } from "react-icons/fa";
import { IoLockClosed, IoRocketSharp } from "react-icons/io5";

import { SiTesla } from "react-icons/si";

const features = [
    {
        name: "No hardware necessary",
        description:
            "All you need to run DashDisplay is an iPhone, a MacBook and a USB cable. Load up the display using your in-car browser.",
        icon: BsWifi,
    },
    {
        name: "100% local. 100% private.",
        description:
            "DashDisplay operates over a local network created by your laptop, so your screen stays private and doesn't consume any of your hotspot data.",
        icon: IoLockClosed,
    },

    {
        name: "Ludicrously easy",
        description: "Start using DashDisplay in your car in under 5 minutes.",
        icon: BsStars,
    },
    {
        name: "Low latency",
        description:
            "Fast enough to watch videos, browse the internet, and work from your car.",
        icon: IoRocketSharp,
    },
    {
        name: "Supports S, 3, X and Y",
        description:
            "DashDisplay works on any Model 3 or Y, as well as most Model S & X with the second generation media computer (Intel MCU 2). See compatibility chart.",
        icon: SiTesla,
    },
    {
        name: "Mac (available May 2023) & Windows (coming soon)",
        description:
            "Supports all recent Macs (Intel & M1). Windows support coming soon.",
        icon: () => (
            <>
                <Box mr="10px">
                    <BsApple size="25" />
                </Box>
                <Box>
                    <BsWindows size="20" />
                </Box>
            </>
        ),
    },
];

const Features = () => (
    <Box as="section" bg="gray.50" id="features">
        <Container py={{ base: "16", md: "24" }} maxW="100ch">
            <Stack spacing={{ base: "12", md: "16" }}>
                <Stack
                    spacing={{ base: "4", md: "5" }}
                    align="center"
                    textAlign="center"
                >
                    <Stack spacing="3">
                        <Text
                            fontSize={{ base: "md", md: "lg" }}
                            fontWeight="semibold"
                            color="accent"
                        >
                            Features
                        </Text>
                        <Heading
                            size={useBreakpointValue({ base: "lg", md: "lg" })}
                        >
                            How does it work?
                        </Heading>
                    </Stack>
                    <Text
                        color="muted"
                        fontSize={{ base: "lg", md: "xl" }}
                        maxW="3xl"
                    >
                        DashDisplay is the only app on the market that lets you
                        use your Tesla as a second monitor for your laptop–no
                        extra hardware required.
                    </Text>
                </Stack>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    columnGap={8}
                    rowGap={{ base: 10, md: 16 }}
                >
                    {features.map((feature) => (
                        <Stack
                            key={feature.name}
                            spacing={{ base: "4", md: "5" }}
                            align="center"
                            textAlign="center"
                        >
                            <Square
                                size={{ base: "10", md: "12" }}
                                bg="accent"
                                color="inverted"
                                borderRadius="lg"
                            >
                                <Icon
                                    as={feature.icon}
                                    boxSize={{ base: "5", md: "6" }}
                                />
                            </Square>
                            <Stack spacing={{ base: "1", md: "2" }}>
                                <Text
                                    fontSize={{ base: "lg", md: "xl" }}
                                    fontWeight="medium"
                                >
                                    {feature.name}
                                </Text>
                                <Text color="muted">{feature.description}</Text>
                            </Stack>
                        </Stack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
    </Box>
);

export default Features;
