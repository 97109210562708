import React from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import ComingSoonEmailCaptureForm from "../components/ComingSoonEmailCaptureForm";
import Pricing from "../components/Pricing";

export default function Home() {
    return (
        <>
            <Hero />
            <Features />
            <ComingSoonEmailCaptureForm />
        </>
    );
}
