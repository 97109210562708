import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Divider,
    IconButton,
    SimpleGrid,
    Stack,
    Text,
} from "@chakra-ui/react";
import * as React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import Logo from "./Logo";

const links = [
    {
        title: "about",
        links: [
            { label: "About", href: "#" },
            { label: "Features", href: "/#features" },
            // { label: "Pricing", href: "/#pricing" },
        ],
    },
    {
        title: "Support",
        links: [
            { label: "Documentation", href: "/docs" },
            // { label: "Resend Download", href: "#" },
            { label: "Contact Support", 
            onClick: (e) => {
                if (window.$crisp) {
                    window.$crisp.push(['do', 'chat:open']);
                }
                e.preventDefault();
                e.stopPropagation();
            }},
            // { label: "Give Feedback", href: "#" },
        ],
    },
    {
        title: "Legal",
        links: [
            { label: "Terms of Service", href: "/terms-of-service" },
            { label: "Privacy Policy", href: "/privacy-policy" },
            // { label: "Refund Policy", href: "#" },
        ],
    },
];

const Footer = () => (
    <Box bg="rgba(0,0,0,0.9)" color="white">
        <Container as="footer" role="contentinfo" maxW="100ch">
            <Stack
                justify="space-between"
                align="start"
                direction={{ base: "column", lg: "row" }}
                py={{ base: "12", md: "16" }}
                spacing="8"
                pl="20px"
            >
                <Stack spacing={{ base: "6", md: "8" }} align="start">
                    <Logo />
                    <Text color="on-accent-muted">
                        Use your Tesla as a second monitor while parked.
                    </Text>
                </Stack>
                <SimpleGrid
                    columns={{ base: 2, md: 3, lg: 3 }}
                    gap="8"
                    width={{ base: "full", lg: "auto" }}
                >
                    {links.map((group, idx) => (
                        <Stack key={idx} spacing="4" minW={{ lg: "40" }}>
                            <Stack spacing="1" shouldWrapChildren>
                                {group.links.map((link, idx) => (
                                    <Button
                                        key={idx}
                                        as="a"
                                        fontWeight="normal"
                                        variant="link"
                                        color="white"
                                        mb="10px"
                                        cursor="pointer"
                                        href={link.href}
                                        onClick={link.onClick || (() => {})}
                                    >
                                        {link.label}
                                    </Button>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>

        <Box bg="rgba(0,0,0,0.2)" color="white" mt="0px">
            <Container as="footer" role="contentinfo" maxW="100ch">
                <Stack
                    pt="12"
                    pb="12"
                    justify="space-between"
                    direction={{ base: "column-reverse", md: "row" }}
                    align="center"
                >
                    <Text fontSize="sm" color="on-accent-subtle">
                        &copy; {new Date().getFullYear()} WarpSpeed Ventures
                        LLC. All rights reserved.
                    </Text>
                    {/* <ButtonGroup variant="ghost-on-accent">
          <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton
            as="a"
            href="#"
            aria-label="GitHub"
            icon={<FaGithub fontSize="1.25rem" />}
          />
          <IconButton
            as="a"
            href="#"
            aria-label="Twitter"
            icon={<FaTwitter fontSize="1.25rem" />}
          />
        </ButtonGroup> */}
                </Stack>
            </Container>
        </Box>
    </Box>
);

export default Footer;
