import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "./constants/chakraTheme";
import Routes from "./components/Routes";
import "focus-visible/dist/focus-visible";

function App() {
  return (
    <ChakraProvider theme={chakraTheme}>
      <Routes />
    </ChakraProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
