import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "../screens/Home";
import TermsOfService from "../screens/TermsOfService";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import Documentation from "../screens/Documentation";

export default function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/terms-of-service" element={<TermsOfService />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/docs/*" element={<Documentation />}></Route>
            </Routes>
            <Footer />
        </Router>
    );
}
